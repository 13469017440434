.dropdown {
	display: block;
  }
  
  .dropdown-menu {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	min-width: 12rem;
  }
  
  .dropdown-item {
	color:$color;
  }
  
  .dropdown-menu-arrow.dropdown-menu-right {
	&:before, &:after {
	  left: auto;
	  right: 12px;
	}
  }
  
  .dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
  
	&:after {
	  vertical-align: 0.155em;
	}
  
	&:empty:after {
	  margin-left: 0;
	}
  }
  
  .dropdown-icon {
	color: #8499c4;
	margin-right: .5rem;
	margin-left: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
  }
  
  /*------Drop Downs-------*/
  
  .dropup, .dropright, .dropdown, .dropleft {
	position: relative;
  }
  
  .dropdown-toggle {
	&::after {
	  display: inline-block;
	  width: 0;
	  height: 0;
	  margin-left: 0.255em;
	  vertical-align: 0.255em;
	  content: "";
	  border-top: 0.3em solid;
	  border-right: 0.3em solid transparent;
	  border-bottom: 0;
	  border-left: 0.3em solid transparent;
	}
  
	&:empty::after {
	  margin-left: 0;
	}
  }
  
  .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9375rem;
	color:$color;
	text-align: left;
	list-style: none;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .dropdown-menu-right {
	right: 0;
	left: auto;
  }
  
  .dropup {
	.dropdown-menu {
	  top: auto;
	  bottom: 100%;
	  margin-top: 0;
	  margin-bottom: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0;
		border-right: 0.3em solid transparent;
		border-bottom: 0.3em solid;
		border-left: 0.3em solid transparent;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
	}
  }
  
  .dropright {
	.dropdown-menu {
	  top: 0;
	  right: auto;
	  left: 100%;
	  margin-top: 0;
	  margin-left: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0;
		border-bottom: 0.3em solid transparent;
		border-left: 0.3em solid;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
  
	  &::after {
		vertical-align: 0;
	  }
	}
  }
  
  .dropleft {
	.dropdown-menu {
	  top: 0;
	  right: 100%;
	  left: auto;
	  margin-top: 0;
	  margin-right: 0.125rem;
	}
  
	.dropdown-toggle {
	  &::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		display: none;
	  }
  
	  &::before {
		display: inline-block;
		width: 0;
		height: 0;
		margin-right: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid transparent;
		border-right: 0.3em solid;
		border-bottom: 0.3em solid transparent;
	  }
  
	  &:empty::after {
		margin-left: 0;
	  }
  
	  &::before {
		vertical-align: 0;
	  }
	}
  }
  
  .dropdown-menu {
	&[x-placement^="top"], &[x-placement^="right"], &[x-placement^="bottom"], &[x-placement^="left"] {
	  right: auto;
	  bottom: auto;
	}
  }
  
  .dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .drop-heading {
	padding: 1rem 1rem;
  }
  
  .notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 40px;
	margin-right: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
  }
  
  .dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1.5rem;
	clear: both;
	font-weight: 400;
	color:$color;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
  }
  
  .drop-icon-wrap {
	text-align: center;
  
	li {
	  display: inline-block;
	}
  
	.drop-icon-item {
	  display: inline-block;
	  padding-bottom: 10px;
	  padding-top: 10px;
	  text-align: center;
	  color: #878787;
	  text-transform: capitalize;
	  min-width: 75px;
	}
  }
  
  .block {
	display: block !important;
  }
  
  .drop-icon-item i {
	font-size: 20px;
	padding-bottom: 5px;
  }
  
  .drop-icon-wrap .drop-icon-item:hover {
	text-decoration: none;
	background-color: #f4f6fd;
  }
  
  .dropdown-item.user {
	font-size: 18px;
	padding: 5px;
  }
  
  .user-semi-title {
	font-size: 14px;
	line-height: 23px;
  }
  
  .floating {
	animation: floating 3s ease infinite;
	will-change: transform;
  }
  
  .dropdown-item {
	&:hover, &:focus, &.active, &:active {
	  text-decoration: none;
	  background-color: #f4f6fd;
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: transparent;
	}
  }
  
  .dropdown-menu.show {
	display: block;
	margin: 0;
	border: 1px solid #eaf2f6;
  }
  
  .dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #8499c4;
	white-space: nowrap;
  }
  
  .dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color:$color;
  }
  
  /*-----List Of Dropdwons-----*/
  
  btn.dropdown-toggle ~ .dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
  }
  
  ul.dropdown-menu li.dropdown ul.dropdown-menu {
	background-color: rgb(244, 244, 244) !important;
	background-color: rgb(255, 255, 255) !important;
	border: 0 solid rgb(66, 133, 244) !important;
	box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
	top: 0px !important;
	margin: 0px !important;
	padding: 0px !important;
	position: absolute !important;
	width: 100% !important;
  }
  
  .dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 9999;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	list-style: none;
	font-size: 14px;
	background-color: $white;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
  
	> li > a {
	  display: block;
	  padding: 8px 20px;
	  clear: both;
	  font-weight: 400;
	  line-height: 1.428571429;
	  color: #8499c4;
	  white-space: nowrap;
  
	  &:hover, &:focus {
		text-decoration: none;
		color: #8499c4;
		background-color: #f4f6fd;
	  }
	}
  
	.divider {
	  height: 1px;
	  margin: 9px 0;
	  overflow: hidden;
	  background-color: rgba(215, 227, 243, 0.5);
	}
  
	.dropdown-plus-title {
	  width: 100% !important;
	  color:$color !important;
	  padding: 6px 12px !important;
	  font-weight: 500 !important;
	  border: 0 solid rgba(215, 227, 243, 0.5) !important;
	  border-bottom-width: 1px !important;
	  cursor: pointer !important;
	}
  }
  
  ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 20px !important;
  }
  
  .btn.dropdown-toggle {
	&.btn-primary ~ .dropdown-menu .dropdown-plus-title {
	  border-color: rgb(53, 126, 189) !important;
	}
  
	&.btn-success ~ .dropdown-menu .dropdown-plus-title {
	  border-color: rgb(76, 174, 76) !important;
	}
  
	&.btn-info ~ .dropdown-menu .dropdown-plus-title {
	  border-color: rgb(70, 184, 218) !important;
	}
  
	&.btn-warning ~ .dropdown-menu .dropdown-plus-title {
	  border-color: rgb(238, 162, 54) !important;
	}
  
	&.btn-danger ~ .dropdown-menu .dropdown-plus-title {
	  border-color: rgb(212, 63, 58) !important;
	}
  }
  
  .dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-bottom: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .dropdown-demo .dropdown-menu {
	position: static;
	float: none;
  }
  
  .dropdown-menu-header label {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #8499c4;
  }
  
  .dropdown-media-list .media {
	&:hover, &:focus {
	  background-color: #e5e9f5;
	}
  
	padding: 12px 15px;
  }
  
  .media {
	display: flex;
	align-items: flex-start;
  }
  
  .dropdown-media-list {
	img {
	  border-radius: 100%;
	}
  
	.media-body {
	  margin-left: 15px;
  
	  > {
		div {
		  display: flex;
		  align-items: center;
		  justify-content: space-between;
  
		  p {
			margin-bottom: 0;
			font-weight: 500;
			color: #8499c4;
			font-size: 14px;
		  }
  
		  span {
			font-size: 12px;
			color: #CFCFCF;
		  }
		}
  
		p {
		  font-size: 14px;
		  color: #bfbfbf;
		  margin-bottom: 0;
		}
	  }
	}
  }
  
  .dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-top: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .label {
	display: inline-block;
	margin-bottom: .5rem;
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 3px;
  }
  
  .dropdown-media-list {
	padding: 0;
  }
  
  @media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	  padding-top: 15px !important;
	  padding-bottom: 15px !important;
	}
  }
  
  @media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
	  width: auto !important;
	}
  }
  
  .dropdown-menu-arrow:before {
	content: '';
	width: 13px;
	height: 13px;
	background: $white;
	position: absolute;
	top: -7px;
	right: 28px;
	transform: rotate(45deg);
	border-top: 1px solid rgba(215, 227, 243, 0.5);
	border-left: 1px solid rgba(215, 227, 243, 0.5);
	z-index: -1;
  }
  
  .dropdown-menu.header-search:before {
	content: '';
	width: 13px;
	height: 13px;
	background: $white;
	position: absolute;
	top: -7px;
	left: 28px;
	transform: rotate(45deg);
	border-top: 1px solid rgba(215, 227, 243, 0.5);
	border-left: 1px solid rgba(215, 227, 243, 0.5);
	z-index: -1;
  }