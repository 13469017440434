/*----Profile----*/

.media {
	display: flex;
	align-items: flex-start;
  }
  
  .mediaicon {
	font-size: 18px;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 9px 0px;
	border-radius: 20px;
  }
  
  .mediafont {
	font-size: 16px;
	font-weight: 600;
  }
  
  .profie-img .flex-md-row img {
	width: 60px;
  }
  
  .profile-img {
	display: block;
	border: 1px solid rgba(167, 180, 201, 0.2);
	border-radius: 50%;
	font-size: 10px;
	text-align: center;
	background: rgba(225, 225, 225, 0.2);
	z-index: 1000;
	position: relative;
  }
  
  .cover-image {
	background-size: cover !important;
  }
  
  /*-----user-profile----*/
  
  #user-profile {
	h2 {
	  padding-right: 15px;
	}
  
	.profile-status {
	  font-size: 0.75em;
	  margin-top: -10px;
	  color: #7e47a9;
  
	  &.offline {
		color: #fe635f;
	  }
	}
  
	.profile-img {
	  padding: 4px;
	}
  
	.profile-label {
	  text-align: center;
  
	  .label {
		padding: 5px 15px;
		font-size: 1em;
	  }
	}
  
	.profile-stars {
	  color: #FABA03;
	  padding: 7px 0;
	  text-align: center;
  
	  > i {
		margin-left: -2px;
	  }
	}
  
	.profile-since {
	  text-align: center;
	  margin-top: -5px;
	}
  
	.profile-details {
	  ul {
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 40px;
  
		> li {
		  margin: 3px 0;
		  line-height: 1.5;
		}
	  }
  
	  a > i {
		padding-top: 2px;
	  }
  
	  ul > li > span {
		color: #643ba2;
	  }
	}
  
	.profile-header {
	  position: relative;
  
	  > h3 {
		margin-top: 10px;
	  }
  
	  .edit-profile {
		margin-top: -6px;
		position: absolute;
		right: 0;
		top: 0;
	  }
	}
  
	.profile-tabs {
	  margin-top: 30px;
	}
  
	.profile-user-info {
	  padding-bottom: 20px;
  
	  .profile-user-details {
		position: relative;
		padding: 4px 0;
  
		.profile-user-details-label {
		  width: 110px;
		  float: left;
		  bottom: 0;
		  font-weight: bold;
		  left: 0;
		  position: absolute;
		  text-align: right;
		  top: 0;
		  width: 110px;
		}
	  }
	}
  
	.profile-image {
	  position: absolute;
	  top: 33px;
	  right: 30%;
	  display: block;
	  border: 1px solid rgba(167, 180, 201, 0.2);
	  border-radius: 50%;
	  font-size: 10px;
	  line-height: 24px;
	  width: 24px;
	  text-align: center;
	  height: 24px;
	  background: rgba(240, 243, 249, 0.6);
	  z-index: 1000;
  
	  &:hover {
		background: rgba(164, 166, 169, 0.9);
	  }
	}
  }
  
  @media only screen and (max-width: 767px) {
	#user-profile {
	  .profile-user-info .profile-user-details {
		.profile-user-details-label {
		  float: none;
		  position: relative;
		  text-align: left;
		}
  
		.profile-user-details-value {
		  margin-left: 0;
		}
	  }
  
	  .profile-social {
		margin-top: 20px;
	  }
	}
  }
  
  @media only screen and (max-width: 420px) {
	#user-profile {
	  .profile-header .edit-profile {
		display: block;
		position: relative;
		margin-bottom: 15px;
	  }
  
	  .profile-message-btn .btn {
		display: block;
	  }
	}
  }
  
  .icon-icon-shape {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	text-align: center;
  
	i {
	  font-size: 24px;
	  line-height: 2;
	}
  }
  
  .header .header-brand-img.mobile-view-logo {
	display: none;
  }
  
  .settings {
	content: "\e8c3";
	top: -7px;
	font-size: 18px;
	position: absolute;
	right: 4px;
	font-family: "feather";
	z-index: 1029;
	line-height: 1;
	color: $white;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	-webkit-animation: anim-rotate 1.5s linear infinite;
	animation: anim-rotate 1.5s linear infinite;
  }
  
  .setting-1 {
	content: "\62";
	top: 0;
	font-size: 120px;
	position: absolute;
	right: -45px;
	font-family: "pct";
	z-index: 1001;
	line-height: 0;
	color: #04a9f5;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	text-shadow: 1px 0 2px rgba(4, 169, 245, 0.9), 3px 0 5px rgba(0, 0, 0, 0.1);
	letter-spacing: -44px;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
  }
  
  .wideget-user-desc {
	.wideget-user-img img {
	  border-radius: 100%;
	  width: 100px;
	  height: 100px;
	  margin: 0 auto;
	  box-shadow: 0px 3px 10px 0 rgba(7, 16, 31, 0.15);
	}
  
	.user-wrap {
	  margin-top: 1.5rem;
	}
  }
  
  .wideget-user .wideget-user-icons a {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	text-align: center;
	border-radius: 100px;
	line-height: 2rem;
	margin-top: 0.3rem;
  
	&:hover {
	  color: $white;
	}
  }
  
  .wideget-user-info {
	.wideget-user-warap {
	  display: flex;
  
	  h4 {
		font-size: 1.5rem;
		font-weight: 500;
		margin-bottom: 0.4rem;
	  }
  
	  .wideget-user-warap-r {
		margin-left: 10rem;
	  }
	}
  
	.wideget-user-rating a {
	  font-size: 18px;
	}
  }
  
  .wideget-user-tab .tab-menu-heading {
	padding: 0;
	border: 0;
  
	.nav li a {
	  color: #374254;
	  font-size: 15px;
	  font-weight: 400;
	}
  }
  
  .usertab-list {
	display: inline-block;
  
	li {
	  width: 50%;
	  margin-bottom: 1rem;
	  float: left;
	}
  }
  
  .wideget-user-tab .tabs-menu1 ul li a {
	padding: 14px 20px 14px 20px;
	display: block;
  }
  
  .item-box {
	.item-box-wrap {
	  margin-top: 15px;
	}
  
	.stamp i {
	  font-size: 18px;
	  font-size: 25px;
	  line-height: 2;
	}
  
	.item-box-wrap h5 {
	  font-size: 20px;
	  font-weight: 500;
	}
  }
  
  .widgets-cards .widgets-cards-data {
	margin-top: 8px;
  
	.wrp p:first-child {
	  font-size: 20px;
	  font-weight: 500;
	}
  }