.error-page {
    position: relative;
  }
  
  .about-img {
    background: linear-gradient(rgba(112, 64, 165, 0.3), rgba(112, 64, 165, 0.3)), url('../images/about4.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .login-img {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-table;
  }
  
  .error-image {
    background: url('../images/pngs/error.png');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    overflow-y: scroll;
    position: relative;
  }
  
  .construction-img {
    background: url('../images/construction.jpg');
    background-size: cover;
  }
  
  .error-template h2 {
    font-size: 3.5rem;
  }
  
  .error-page .display-1 {
    font-size: 10.5rem;
    margin-top: 0px;
  }
  
  .container-login100 .box {
    .social-icons {
      position: relative;
    }
  
    ul {
      margin: 0;
      padding: 0;
  
      li {
        list-style: none;
        float: left;
        padding: 7px 7px;
  
        a {
          margin: 0 10px;
          font-size: 20px;
          transition: 0.5s;
          text-align: center;
        }
      }
    }
  }