/*------ Charts styles ------*/

.chart-visitors {
	min-height: 18rem;
	overflow: hidden;
  }
  
  .chart-tasks {
	height: 15rem;
	overflow: hidden;
  }
  
  .chart-donut {
	height: 21rem;
	overflow: hidden;
  }
  
  .chart-pie {
	height: 22rem;
	overflow: hidden;
  }
  
  .chartsh {
	height: 16rem;
	overflow: hidden;
  }
  
  .chartwidget {
	height: 17rem;
	overflow: hidden;
  }
  
  /*----chart-drop-shadow----*/
  
  .chart-dropshadow {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgb(227, 228, 237));
	filter: drop-shadow((-6px) 5px 4px rgb(227, 228, 237));
  }
  
  .chart-dropshadow2 {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(0, 0, 0, 0.2));
  }
  
  .chart-dropshadow-secondary {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(89, 200, 227 ,0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(89, 200, 227, 0.2));
  }
  
  .chart-dropshadow-danger {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(248, 38, 73, 0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(248, 38, 73, 0.2));
  }
  
  .chart-dropshadow-success {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(19, 191, 166, 0.2));
  }
  
  .chart-dropshadow-warning {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(251, 141, 52, 0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(251, 141, 52, 0.2));
  }
  
  .chart-dropshadow-info {
	-webkit-filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248 ,0.2));
	filter: drop-shadow((-6px) 5px 4px rgba(7, 116, 248, 0.2));
  }
  
  .BarChartShadow {
	-webkit-filter: drop-shadow((-4px) 9px 4px rgba(0, 0, 0, 0.3));
	filter: drop-shadow((-6px) 9px 4px rgba(0, 0, 0, 0.3));
  }
  
  .donutShadow {
	-webkit-filter: drop-shadow((-5px) 4px 6px rgb(227, 228, 237));
	filter: drop-shadow((-1px) 0px 2px rgb(227, 228, 237));
  }
  
  .map, .chart {
	position: relative;
	padding-top: 56.25%;
  }
  
  .map-square, .chart-square {
	padding-top: 100%;
  }
  
  .map-content, .chart-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
  }
  
  .map-header {
	margin-bottom: 1.5rem;
	height: 15rem;
	position: relative;
	margin-bottom: -1.5rem;
  
	&:before {
	  content: '';
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  height: 10rem;
	  background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, #f4f6fd 95%);
	  pointer-events: none;
	}
  }
  
  .map-header-layer {
	height: 100%;
  }
  
  .map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
  }
  
  @-webkit-keyframes status-pulse {
	0%, 100% {
	  opacity: 1;
	}
  
	50% {
	  opacity: .32;
	}
  }
  
  @keyframes status-pulse {
	0%, 100% {
	  opacity: 1;
	}
  
	50% {
	  opacity: .32;
	}
  }
  
  .chart-circle {
	height: 7rem;
	width: 7rem;
	position: relative;
  }
  
  .chart-circle-1 {
	display: initial;
	height: 12rem;
	width: 12rem;
	position: relative;
  }
  
  .chart-circle canvas {
	margin: 0 auto;
	display: block;
	max-width: 100%;
	max-height: 100%;
  }
  
  .chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
  }
  
  .chart-circle-sm {
	height: 3rem !important;
	width: 3rem !important;
	font-size: .5rem;
  }
  
  .chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
  }
  
  .chart-circle-value {
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
  
	small {
	  display: block;
	  color: #8499c4;
	  font-size: 0.9375rem;
	}
  }
  
  .chart-circle-1-value {
	position: absolute;
	top: -184px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	font-size: 40px;
  
	small {
	  display: block;
	  color: #8499c4;
	  font-size: 0.9375rem;
	}
  }
  
  .chart-circle-value-3 {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
  }
  
  .chart-circle-value-4 {
	position: absolute;
	top: -66px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	font-size: 50px;
  }
  
  .highlight {
	.hll {
	  background-color: #ffc;
	}
  
	.c {
	  color: #999;
	}
  
	.k {
	  color: #069;
	}
  
	.o {
	  color: #555;
	}
  
	.cm {
	  color: #999;
	}
  
	.cp {
	  color: #099;
	}
  
	.c1, .cs {
	  color: #999;
	}
  
	.gd {
	  background-color: #fcc;
	  border: 1px solid #c00;
	}
  
	.ge {
	  font-style: italic;
	}
  
	.gr {
	  color: #f00;
	}
  
	.gh {
	  color: #030;
	}
  
	.gi {
	  background-color: #cfc;
	  border: 1px solid #0c0;
	}
  
	.go {
	  color: #aaa;
	}
  
	.gp {
	  color: #009;
	}
  
	.gu {
	  color: #030;
	}
  
	.gt {
	  color: #9c6;
	}
  
	.kc, .kd, .kn, .kp, .kr {
	  color: #069;
	}
  
	.kt {
	  color: #078;
	}
  
	.m {
	  color: #f60;
	}
  
	.s {
	  color: #cc0099;
	}
  
	.na {
	  color: #00cc7a;
	}
  
	.nb {
	  color: #366;
	}
  
	.nc {
	  color: #0a8;
	}
  
	.no {
	  color: #360;
	}
  
	.nd {
	  color: #99f;
	}
  
	.ni {
	  color: #999;
	}
  
	.ne {
	  color: #c00;
	}
  
	.nf {
	  color: #c0f;
	}
  
	.nl {
	  color: #99f;
	}
  
	.nn {
	  color: #0cf;
	}
  
	.nt {
	  color: #800000;
	}
  
	.nv {
	  color: #033;
	}
  
	.ow {
	  color: $black;
	}
  
	.w {
	  color: #bbb;
	}
  
	.mf, .mh, .mi, .mo {
	  color: #f60;
	}
  
	.sb, .sc {
	  color: #c30;
	}
  
	.sd {
	  font-style: italic;
	  color: #c30;
	}
  
	.s2, .se, .sh {
	  color: #c30;
	}
  
	.si {
	  color: #a00;
	}
  
	.sx {
	  color: #c30;
	}
  
	.sr {
	  color: #3aa;
	}
  
	.s1 {
	  color: #c30;
	}
  
	.ss {
	  color: #fc3;
	}
  
	.bp {
	  color: #366;
	}
  
	.vc, .vg, .vi {
	  color: #033;
	}
  
	.il {
	  color: #f60;
	}
  
	.css {
	  .o {
		color: #999;
  
		+ .nt {
		  color: #999;
		}
	  }
  
	  .nt + .nt {
		color: #999;
	  }
	}
  
	.language-bash::before, .language-sh::before {
	  color: #009;
	  content: "$ ";
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}
  
	.language-powershell::before {
	  color: #009;
	  content: "PM> ";
	  -webkit-user-select: none;
	  -moz-user-select: none;
	  -ms-user-select: none;
	  user-select: none;
	}
  }
  
  /*----chart-drop-shadow----*/
  
  .chart-dropshadow {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
	filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
  }
  
  .chart-dropshadow2 {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.2));
	filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.2));
  }
  
  .chart-dropshadow-primary {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.5));
	filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.5));
  }
  
  .chart-dropshadow-primary-1 {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
	filter: drop-shadow((-6px) 12px 4px rgba(133, 67, 246, 0.2));
  }
  
  .chart-dropshadow-danger {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(244, 88, 91, 0.5));
	filter: drop-shadow((-6px) 12px 4px rgba(244, 88, 91, 0.5));
  }
  
  .chart-dropshadow-success {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(69, 213, 85, 0.5));
	filter: drop-shadow((-6px) 12px 4px rgba(69, 213, 85, 0.5));
  }
  
  .chart-dropshadow-warning {
	-webkit-filter: drop-shadow((-6px) 12px 4px rgba(251, 141, 52, 0.5));
	filter: drop-shadow((-6px) 12px 4px rgba(251, 141, 52, 0.5));
  }
  
  .GradientlineShadow {
	-webkit-filter: drop-shadow(0 -8px 4px rgba(0, 0, 0, 0.2));
	filter: drop-shadow(0 -8px 4px rgba(0, 0, 0, 0.2));
  }
  
  .BarChartShadow {
	-webkit-filter: drop-shadow((-4px) 9px 4px rgba(0, 0, 0, 0.3));
	filter: drop-shadow((-6px) 9px 4px rgba(0, 0, 0, 0.3));
  }
  
  .donutShadow {
	-webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
	filter: drop-shadow((-1px) 0px 2px rgba(159, 120, 255, 0.5));
  }
  
  .donutShadow-yellow {
	-webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
	filter: drop-shadow((-1px) 0px 2px rgba(251, 196, 52, 0.5));
  }
  
  .donutShadow-blue {
	-webkit-filter: drop-shadow((-5px) 4px 6px rgba(0, 0, 0, 0.5));
	filter: drop-shadow((-1px) 0px 2px rgba(36, 72, 135, 0.5));
  }
  
  .highcharts-credits, .highcharts-button, .highcharts-data-label, .highcharts-label {
	display: none;
  }
  
  .highcharts-data-label-connector {
	display: none !important;
  }
  
  #orders {
	position: relative;
	bottom: -10px;
	height: 102px !important;
	width: 100% !important;
  }
  
  #profit {
	position: relative;
	bottom: -13px;
	height: 111px !important;
  }
  
  #total-customers {
	position: relative;
	left: -11px;
  }
  
  #sales-revenue {
	position: relative;
	bottom: -13px;
  }
  
  #resolved-complaints {
	position: relative;
	bottom: -13px;
	left: -4px;
	height: 199px;
  }
  
  #areaChart1, #areaChart2, #areaChart3, #areaChart4 {
	height: 7rem !important;
  }