.pagination-simple .page-item {
	.page-link {
	  background: none;
	  border: none;
	}
  
	&.active .page-link {
	  color:$color;
	  font-weight: 700;
	}
  }
  
  .pagination-pager {
	.page-prev {
	  margin-right: auto;
	}
  
	.page-next {
	  margin-left: auto;
	}
  }