/*--------- Height--------------*/

.h-25 {
	height: 25% !important;
  }
  
  .h-50 {
	height: 50% !important;
  }
  
  .h-75 {
	height: 75% !important;
  }
  
  .h-90 {
	height: 90px !important;
  }
  
  .h-60 {
	height: 60px !important;
  }
  
  .h-100 {
	height: 100% !important;
  }
  
  .h-150 {
	height: 150px !important;
  }
  
  .h-160 {
	height: 160px !important;
  }
  
  .h-180 {
	height: 180px !important;
  }
  
  .h-400 {
	height: 400px !important;
  }
  
  .h-430 {
	height: 430px !important;
  }
  
  .h-450 {
	height: 450px !important;
  }
  
  .h-200 {
	height: 200px !important;
  }
  
  .h-210 {
	height: 210px !important;
  }
  
  .h-220 {
	height: 220px !important;
  }
  
  .h-230 {
	height: 230px !important;
  }
  
  .h-500 {
	height: 500px !important;
  }
  
  .h-550 {
	height: 550px !important;
  }
  
  .h-600 {
	height: 600px !important;
  }
  
  .h-650 {
	height: 650px !important;
  }
  
  .h-auto {
	height: auto !important;
  }
  
  .h-0 {
	height: 0 !important;
  }
  
  .h-1 {
	height: 0.25rem !important;
  }
  
  .h-2 {
	height: 0.5rem !important;
  }
  
  .h-3 {
	height: 0.75rem !important;
  }
  
  .h-4 {
	height: 1rem !important;
  }
  
  .h-5 {
	height: 1.5rem !important;
  }
  
  .h-6 {
	height: 2rem !important;
  }
  
  .h-7 {
	height: 3rem !important;
  }
  
  .h-8 {
	height: 4rem !important;
  }
  
  .h-9 {
	height: 6rem !important;
  }
  
  .h-97 {
	height: 97px !important;
  }
  
  .h-240 {
	height: 240px !important;
  }
  
  .h-250 {
	height: 250px !important;
  }
  
  .h-260 {
	height: 260px !important;
  }
  
  .h-270 {
	height: 270px !important;
  }
  
  .h-280 {
	height: 280px !important;
  }
  
  .h-300 {
	height: 300px !important;
  }
  
  .h-340 {
	height: 340px !important;
  }
  
  .w-90 {
	width: 90% !important;
  }
  
  .mw-100 {
	max-width: 100% !important;
  }
  
  .mh-100 {
	max-height: 100% !important;
  }