/*------- Forms -------*/

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	height: 38px;
	font-size: 0.875rem;
	line-height: 1.6;
	color:$color !important;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #d3dfef;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	border-radius: 5px;
  }
  
  .authentication {
	input {
	  border: 0;
	  padding-left: 32px !important;
	  border-radius: 0;
	}
  
	.card:hover {
	  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
	}
  
	.card-title {
	  font-size: 1.185rem;
	}
  
	.form-control {
	  &:focus {
		box-shadow: none;
	  }
  
	  margin-bottom: 1rem;
	}
  
	input::placeholder {
	  color: #8499c4;
	  margin-top: 1px;
	}
  
	.flex-c-m .login100-social-item i {
	  color: $white;
	  margin-left: 0;
	}
  
	.wrap-input {
	  width: 100%;
	  margin: 0 auto;
	}
  }
  
  .login100-social-item {
	font-size: 20px;
	color: $white;
	display: inline-block;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 5px;
	text-align: center;
	line-height: 2;
  
	&:hover {
	  color: $white;
	}
  }
  
  .bg1 {
	background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
  }
  
  .bg2 {
	background: linear-gradient(to bottom right, $info 0%, #79b3f9 100%) !important;
  }
  
  .bg3 {
	background: linear-gradient(to bottom right, #f53e31, #dd4b39);
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
	.form-control {
	  transition: none;
	}
  }
  
  .form-control {
	&::-ms-expand {
	  background-color: transparent;
	  border: 0;
	}
  
	&:focus {
	  color:$color;
	  background-color: $white;
	  outline: 0;
	  box-shadow: none;
	  border: 1px solid #bfcfe4 !important;
	}
  
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-ms-input-placeholder {
	  color: #8499c4;
	  opacity: 1;
	}
  
	color: #99c1b2;
	opacity: 1;
  
	&:disabled, &[readonly] {
	  background-color: #edeff9;
	  opacity: 1;
	}
  }
  
  select.form-control {
	&:not([size]):not([multiple]) {
	  height: 2.375rem;
	}
  
	&:focus::-ms-value {
	  color:$color;
	  background-color: $white;
	  border: 1px solid #d5e4f7;
	  border-radius: 5px;
	  box-shadow: none;
	}
  }
  
  .form-control-file, .form-control-range {
	display: block;
	width: 100%;
  }
  
  .col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.6;
  }
  
  .col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.125rem;
	line-height: 1.44444444;
  }
  
  .col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.875rem;
	line-height: 1.14285714;
  }
  
  .form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.6;
	color:$color;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
  
	&.form-control-sm {
	  padding-right: 0;
	  padding-left: 0;
	}
  }
  
  .input-group-sm > {
	.form-control-plaintext.form-control, .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-append > .form-control-plaintext.input-group-text, .input-group-prepend > .form-control-plaintext.btn, .input-group-append > .form-control-plaintext.btn {
	  padding-right: 0;
	  padding-left: 0;
	}
  }
  
  .form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
  }
  
  .input-group-lg > {
	.form-control-plaintext.form-control, .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-append > .form-control-plaintext.input-group-text, .input-group-prepend > .form-control-plaintext.btn, .input-group-append > .form-control-plaintext.btn {
	  padding-right: 0;
	  padding-left: 0;
	}
  }
  
  .form-control-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.14285714;
  }
  
  .input-group-sm > {
	.form-control, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn, .input-group-append > .btn {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.875rem;
	  line-height: 1.14285714;
	}
  }
  
  select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px);
  }
  
  .input-group-sm > {
	select.form-control:not([size]):not([multiple]), .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-append > select.btn:not([size]):not([multiple]) {
	  height: calc(1.8125rem + 2px);
	}
  }
  
  .form-control-lg {
	padding: 0.5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.44444444;
	border-radius: 3px;
  }
  
  .input-group-lg > {
	.form-control, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn, .input-group-append > .btn {
	  padding: 0.5rem 1rem;
	  font-size: 1.125rem;
	  line-height: 1.44444444;
	  border-radius: 3px;
	}
  }
  
  select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.6875rem + 2px);
  }
  
  .input-group-lg > {
	select.form-control:not([size]):not([multiple]), .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-append > select.btn:not([size]):not([multiple]) {
	  height: calc(2.6875rem + 2px);
	}
  }
  
  .form-group {
	margin-bottom: 1rem;
  }
  
  .form-text {
	display: block;
	margin-top: 0.25rem;
  }
  
  .form-row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
  
	> {
	  .col, [class*="col-"] {
		padding-right: 5px;
		padding-left: 5px;
	  }
	}
  }
  
  .form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
  }
  
  .form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
  
	&:disabled ~ .form-check-label {
	  color: #505662;
	}
  }
  
  .form-check-label {
	margin-bottom: 0;
  }
  
  .form-check-inline {
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
  
	.form-check-input {
	  position: static;
	  margin-top: 0;
	  margin-right: 0.3125rem;
	  margin-left: 0;
	}
  }
  
  .valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 87.5%;
	color: $success;
  }
  
  .valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: $white;
	background-color: rgba(94, 186, 0, 0.8);
	border-radius: .2rem;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid, .custom-select.is-valid {
	border-color: $success;
  }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
	border-color: $success;
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
  }
  
  .was-validated .form-control:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .form-control.is-valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-select:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .custom-select.is-valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
	color: $success;
  }
  
  .was-validated .form-check-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .form-check-input.is-valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
	color: $success;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
	background-color: #9eff3b;
  }
  
  .was-validated .custom-control-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .custom-control-input.is-valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
	background-color: #78ed00;
  }
  
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #f4f6fd, 0 0 0 2px rgba(94, 186, 0, 0.25);
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
	border-color: $success;
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
	border-color: inherit;
  }
  
  .was-validated .custom-file-input:valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .custom-file-input.is-valid ~ {
	.valid-feedback, .valid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
  }
  
  .invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 87.5%;
	color: $danger;
  }
  
  .invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: $white;
	background-color: rgba(205, 32, 31, 0.8);
	border-radius: .2rem;
  }
  
  .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
	border-color: $danger;
  }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
	border-color: $danger;
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
  }
  
  .was-validated .form-control:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .form-control.is-invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-select:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .custom-select.is-invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
	color: $danger;
  }
  
  .was-validated .form-check-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .form-check-input.is-invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
	color: $danger;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
	background-color: #ec8080;
  }
  
  .was-validated .custom-control-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .custom-control-input.is-invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
	background-color: #e23e3d;
  }
  
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #f4f6fd, 0 0 0 2px rgba(205, 32, 31, 0.25);
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
	border-color: $danger;
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
	border-color: inherit;
  }
  
  .was-validated .custom-file-input:invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .custom-file-input.is-invalid ~ {
	.invalid-feedback, .invalid-tooltip {
	  display: block;
	}
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
  }
  
  .form-inline {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-ms-flex-align: center;
	align-items: center;
  }
  
  .header .form-inline {
	margin-right: 7px;
  }
  
  .form-inline .form-check {
	width: 100%;
  }
  
  @media (min-width: 576px) {
	.form-inline {
	  label {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-bottom: 0;
	  }
  
	  .form-group {
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 0;
	  }
  
	  .form-control {
		display: inline-block;
		width: auto;
	  }
  
	  .form-control-plaintext {
		display: inline-block;
	  }
  
	  .input-group, .custom-select {
		width: auto;
	  }
  
	  .form-check {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
		width: auto;
		padding-left: 0;
	  }
  
	  .form-check-input {
		position: relative;
		margin-top: 0;
		margin-right: 0.25rem;
		margin-left: 0;
	  }
  
	  .custom-control {
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: center;
		justify-content: center;
	  }
  
	  .custom-control-label {
		margin-bottom: 0;
	  }
	}
  }