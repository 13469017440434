/*------ Headers -------*/

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 1.5rem 0rem;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	padding: 0;
  
	/* border-radius: 7px; */
	position: relative;
	min-height: 50px;
  }
  
  .page-title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	position: relative;
	margin-bottom: 0.3rem;
  }
  
  .page-title-icon {
	color: #8499c4;
	font-size: 1.25rem;
  }
  
  .page-subtitle {
	font-size: 0.8125rem;
	color: #c0a0f1;
	margin-left: 2rem;
	position: relative;
  
	a {
	  color: inherit;
	}
  }
  
  .page-options {
	margin-left: auto;
  }
  
  .page-header .breadcrumb {
	font-size: 12px;
	padding-left: 0 !important;
  }
  
  .page-breadcrumb {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
  }
  
  .page-description {
	margin: .25rem 0 0;
	color: #8499c4;
  
	a {
	  color: inherit;
	}
  }
  
  .page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
  }
  
  .content-heading {
	font-weight: 400;
	margin: 2rem 0 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;
  
	&:first-child {
	  margin-top: 0;
	}
  }
  
  .aside {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 22rem;
	background: $white;
	border-left: 1px solid rgba(234, 238, 251, 0.8);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
  }
  
  @media (min-width: 1600px) {
	body.aside-opened .aside {
	  visibility: visible;
	}
  }
  
  .aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
  }
  
  .aside-footer {
	padding: 1rem 1.5rem;
	border-top: 1px solid rgba(234, 238, 251, 0.8);
  }
  
  .aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid rgba(234, 238, 251, 0.8);
  }
  
  .hor-header.header {
	background: $white;
	border-bottom:1px solid $border;
  }
  
  .header {
	background: $white;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid transparent;
  }
  
  body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
  }
  
  @media print {
	.header {
	  display: none;
	}
  }
  
  .header {
	.dropdown-menu {
	  margin-top: 0;
	}
  
	.mega-menu {
	  width: 350px;
	}
  
	.nav-tabs {
	  margin: 0;
	}
  }
  
  .nav-unread {
	position: absolute;
	top: 0.4rem;
	background: #ecd938;
	width: .5rem;
	height: .5rem;
	border-radius: 50%;
  
	&.badge {
	  position: absolute;
	  top: 4px;
	  left: 26px;
	  display: block !important;
	  padding: 3px 5px !important;
	  width: 1rem;
	  height: 1rem;
	  border-radius: 50%;
	  font-size: 11px;
	}
  }
  
  .nav-link.icon {
	margin: 5px;
	padding: 12px;
	text-align: center;
	height: 2.5rem;
	font-size: 1.2rem;
	position: relative;
  
	i {
	  color: #5371ad !important;
	}
  
	svg {
	  fill: #677ca7;
	}
  }
  
  @media (max-width: 768px) {
	.nav-link.icon {
	  padding: 5px !important;
	}
  }
 
  
  .header-right-icons {
	a {
	  padding-top: 9px;
	}
  
	.dropdown-menu a {
	  padding-top: 0.5rem;
	}
  }
  
  .header-brand {
	color: inherit;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.6rem;
  }
  
  .app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
  
	.header-brand-img {
	  margin-right: 0;
	}
  }
  
  .app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
  
	.header-brand-img {
	  margin-right: 0;
	}
  }
  
  .header-brand:hover {
	color: inherit;
	text-decoration: none;
  }
  
  .header-brand-img {
	height: 2.5rem;
	line-height: 2rem;
	vertical-align: middle;
	margin-right: 0;
	width: auto;
  }
  
  .header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
  }
  
  .header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
  }
  
  .center-block {
	margin-right: auto;
	margin-left: auto;
	float: inherit !important;
  }
  
  @media (max-width: 991px) {
	.header .form-inline {
	  margin-right: -2px;
	}
  }
  
  .header-1 {
	.navbar .nav-link.nav-link-lg i {
	  font-size: 1.1rem;
	  width: 2.2rem;
	  height: 2.2rem;
	  border-radius: 50%;
	  color:$color;
	  line-height: 32px;
	  margin: 0 auto;
	  text-align: center;
	}
  
	.nav-link .badge {
	  position: absolute;
	  top: 3px;
	  right: 6px;
	  width: 1rem;
	  height: 1rem;
	  font-size: 11px;
	  text-align: center !important;
	  padding: 0.25em 0.4em;
	  line-height: 1;
	}
  
	.badge {
	  border-radius: 50%;
	}
  
	.form-inline .btn {
	  border-radius: 0;
	  padding: 5px 15px 6px 15px;
	  border: 2px solid transparent;
	  box-shadow: none;
	  border-bottom-right-radius: 3px;
	  border-top-right-radius: 3px;
	  background: transparent;
	  color:$color;
	  margin-left: -50px;
	  font-size: 15px;
	  position: absolute;
	  margin-top: 0px;
	}
  
	.form-control.header-search {
	  color:$color;
	}
  
	.form-inline .form-control::-webkit-input-placeholder {
	  color: #8499c4;
	}
  
	.animated-arrow span {
	  display: none;
	}
  }
  
  .animated-arrow span {
	&:before, &:after {
	  display: none;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.header-1 .animated-arrow span {
	  cursor: pointer;
	  height: 2px;
	  width: 17px;
	  background:$color;
	  position: absolute;
	  display: block;
	  content: '';
	}
  
	.animated-arrow span {
	  &:before, &:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background:$color;
		position: absolute;
		display: block;
		content: '';
	  }
	}
  
	.header-1 .animated-arrow span {
	  cursor: pointer;
	  height: 2px;
	  width: 17px;
	  background:$color;
	  position: absolute;
	  display: block;
	  content: '';
	}
  
	.animated-arrow span {
	  &:before, &:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background:$color;
		position: absolute;
		display: block;
		content: '';
	  }
	}
  
	.header-1 {
	  .hor-toggle.animated-arrow {
		padding: 83px 35px 16px 20px !important;
		margin: 11px 0 0 0px !important;
	  }
  
	  .animated-arrow {
		cursor: pointer;
		margin: 10px 10px 0 0px;
		position: initial;
		padding: 10px;
	  }
	}
  
	.header-2 .animated-arrow {
	  cursor: pointer;
	  margin: 10px 10px 0 0px;
	  position: initial;
	  padding: 10px;
	}
  
	.header-1 .form-inline .form-control, .header-2 .form-inline .form-control {
	  width: 100PX !important;
	}
  
	.header-1 .header-search, .header-2 .header-search {
	  min-width: auto !important;
	}
  
	.header-1 {
	  .navbar .nav-link.nav-link-lg i {
		font-size: 1.1rem;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: 50%;
		color:$color;
		line-height: 39px;
		margin: 0 auto;
		text-align: center;
		padding: 0px 42px 0px 10px;
	  }
  
	  .dropdown-menu {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
	  }
  
	  .nav-link .badge {
		position: absolute;
		top: 4px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
	  }
	}
  }
  
  .navbar-nav {
	flex-direction: initial !important;
  }
  
  .header-1 {
	.nav-link.icon i {
	  color:$color !important;
	}
  
	.dropdown-menu {
	  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
	}
  }
  
  .header-2 {
	.dropdown-menu {
	  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
	}
  
	.nav-link {
	  .badge {
		position: absolute;
		top: 2px;
		right: 8px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	  }
  
	  i {
		display: block;
		text-align: center;
		font-size: 20px;
		color:$color;
		margin-top: 5px;
	  }
	}
  
	.navbar-expand-lg .navbar-nav .nav-link {
	  padding-right: 0.9rem;
	  padding-left: 0.9rem;
	}
  
	.form-inline {
	  .btn {
		border-radius: 0;
		padding: 5px 15px 6px 15px;
		border: 2px solid transparent;
		box-shadow: none;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background: transparent;
		color:$color;
		margin-left: -45px;
		font-size: 15px;
		position: absolute;
		margin-top: 4px;
	  }
  
	  .form-control::-webkit-input-placeholder {
		color: #8499c4;
	  }
	}
  
	.form-control.header-search {
	  background: rgba(225, 225, 225, 0.4);
	  border: 1px solid rgba(225, 225, 225, 0.1);
	  color: $white;
	  padding: 0.375rem 0.75rem;
	  margin-top: 4px;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.header-1 .animated-arrow span {
	  cursor: pointer;
	  height: 2px;
	  width: 17px;
	  background:$color;
	  color:$color;
	  position: absolute;
	  display: block;
	  content: '';
  
	  &:before, &:after {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background:$color;
		color:$color;
		position: absolute;
		display: block;
		content: '';
	  }
	}
  }
  
  /*--header-2--*/
  
  @media only screen and (max-width: 1279px) and (min-width: 1025px) {
	.horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a {
	  display: block;
	  padding: 1rem 0.7rem 1rem;
	  text-decoration: none;
	  position: relative;
	  margin: 0px;
	  color: #fcfeff;
	}
  }
  
  @media (max-width: 1024px) and (min-width: 992px) {
	.horizontal-main2 .horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 0.8rem 1rem !important;
	  font-size: 14px;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.header-2 {
	  .animated-arrow {
		cursor: pointer;
		margin: 10px 10px 0 0px;
		position: initial;
		padding: 10px;
  
		span {
		  cursor: pointer;
		  height: 2px;
		  width: 17px;
		  background:$color;
		  color:$color;
		  position: absolute;
		  display: block;
		  content: '';
  
		  &:before, &:after {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background:$color;
			color:$color;
			position: absolute;
			display: block;
			content: '';
		  }
		}
	  }
  
	  .nav-link .badge {
		position: absolute;
		top: -1px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	  }
  
	  .navbar-nav .dropdown-menu {
		position: absolute;
		float: right;
	  }
  
	  .dropdown-menu.dropdown-menu-right {
		position: absolute;
		transform: translate3d(-98px, 19px, -16px);
		top: 24px;
		left: 0px;
		will-change: transform;
	  }
	}
  }
  
  .horizontal-main2 {
	.horizontalMenu > .horizontalMenu-list > li > a.active {
	  color:$color;
	}
  
	&.horizontal-main {
	  width: 100%;
	  position: relative;
	  border-top: 0;
	}
  }
  
  @media only screen and (max-width: 460px) {
	.header-2 .navbar-nav .envelope {
	  display: none !important;
	}
  }
  
  /*--header-3--*/
  
  .header-3 {
	.nav-link {
	  .badge {
		position: absolute;
		top: -10px;
		right: 10px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	  }
  
	  i {
		display: block;
		text-align: center;
		font-size: 20px;
		position: relative;
		color:$color;
	  }
	}
  
	.navbar-expand-lg .navbar-nav .nav-link {
	  padding-right: 0.9rem;
	  padding-left: 0.9rem;
	}
  
	.form-inline {
	  .btn {
		border-radius: 0;
		padding: 5px 15px 6px 15px;
		border: 2px solid transparent;
		box-shadow: none;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background: transparent;
		color:$color;
		margin-left: -50px;
		font-size: 15px;
		margin-top: 3px;
	  }
  
	  .form-control::-webkit-input-placeholder {
		color: #8499c4;
	  }
	}
  
	.form-control.header-search {
	  background: rgba(225, 225, 225, 0.4);
	  border: 1px solid rgba(225, 225, 225, 0.1);
	  color: $white;
	  padding: 0.375rem 0.75rem;
	  margin-top: 4px;
	}
  }
  
  @media only screen and (max-width: 1279px) and (min-width: 1025px) {
	.horizontal-main3 .horizontalMenu > .horizontalMenu-list > li > a {
	  display: block;
	  padding: 1rem 0.7rem 1rem;
	  text-decoration: none;
	  position: relative;
	  margin: 0px;
	  color: #fcfeff;
	}
  }
  
  @media only screen and (max-width: 1279px) and (min-width: 992px) {
	#orders {
	  position: relative;
	  bottom: -6px;
	  height: 102px !important;
	  width: 100% !important;
	  left: -6px;
	}
  }
  
  @media (max-width: 1024px) and (min-width: 992px) {
	.horizontal-main3 .horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 0.8rem 1rem !important;
	  font-size: 14px;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.header-3 {
	  .animated-arrow {
		cursor: pointer;
		margin: 10px 10px 0 0px;
		position: initial;
		padding: 10px;
  
		span {
		  cursor: pointer;
		  height: 2px;
		  width: 17px;
		  background:$color;
		  color:$color;
		  position: absolute;
		  display: block;
		  content: '';
  
		  &:before, &:after {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background:$color;
			color:$color;
			position: absolute;
			display: block;
			content: '';
		  }
		}
	  }
  
	  .nav-link .badge {
		position: absolute;
		top: -14px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
		border-radius: 50%;
	  }
  
	  .navbar-nav .dropdown-menu {
		position: absolute;
		float: right;
	  }
  
	  .dropdown-menu.dropdown-menu-right {
		position: absolute;
		transform: translate3d(-98px, 19px, -16px);
		top: 24px;
		left: 0px;
		will-change: transform;
	  }
	}
  }
  
  .horizontal-main3.horizontal-main {
	width: 100%;
	position: relative;
	background: rgba(52, 1, 108, 0.8);
	border-top: 0;
  }
  
  @media only screen and (max-width: 460px) {
	.header-3 .navbar-nav .envelope {
	  display: none !important;
	}
  }
  
  .header-style .header-brand {
	margin-left: 0 !important;
  }
  
  @media screen and (max-width: 998px) and (min-width: 768px) {
	.note-popover .popover-content, .card-header.note-toolbar {
	  padding: 14px 0 5px 5px !important;
	  margin: 0;
	  background: #f4f6fd;
	}
  }
  
  @media only screen and (max-width: 490px) {
	#smartwizard .nav-link {
	  padding: 15px 21px;
	}
  }
  
  @media (max-width: 495px) {
	.nested-media .media {
	  display: block;
	}
  }
  
  .widget-drop {
	.dropdown-menu {
	  position: absolute;
	  transform: translate3d(-156px, 31px, 0px) !important;
	  top: 0px;
	  left: 0px;
	  will-change: transform;
	  min-width: 80px;
	}
  
	.button:focus {
	  border-color: transparant !important;
	}
  }
  
  .default-header .form-inline .form-control, .header2 .form-inline .form-control, .header3 .form-inline .form-control {
	background: #f4f6fd !important;
	box-shadow: none;
  }
  
  .header-1 .nav-link .text-dark, .header-2 .nav-link .text-dark, .header-3 .nav-link .text-dark {
	color:$color !important;
  }
  
  .border-right-1 {
	border-right: 1px solid #d5dce3;
  }
  
  .header-search-icon .dropdown-menu {
	box-shadow: 0 10px 15px rgba(201, 206, 226, 0.4);
	margin-top: 11px !important;
	border: 1px solid rgba(234, 238, 251, 0.8) !important;
  }
  
  .header-search {
	min-width: 18rem !important;
  
	.search-element {
	  position: relative;
	  margin-left: 15px;
	}
  
	.btn {
	  position: absolute;
	  right: 0;
	  top: 0px;
	  color: #8499c4;
	}
  }