/*------ Accordion -------*/

.accordion .card {
	&:not(:first-of-type) {
	  &:not(:last-of-type) {
		border-bottom: 0;
		border-radius: 0;
	  }
  
	  .card-header:first-child {
		border-radius: 0;
	  }
	}
  
	&:first-of-type {
	  border-bottom: 0;
	  border-bottom-right-radius: 0;
	  border-bottom-left-radius: 0;
	}
  
	&:last-of-type {
	  border-top-left-radius: 0;
	  border-top-right-radius: 0;
	}
  }
  
  ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
  }
  
  .example .pagination {
	margin-bottom: 0;
  }
  
  .pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
  }
  
  .page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid rgba(215, 227, 243, 0.5);
	color:$color;
  
	&:hover {
	  z-index: 2;
	  text-decoration: none;
	  background-color: rgba(234, 238, 251, 0.06);
	}
  
	&:focus {
	  z-index: 2;
	  outline: 0;
	  box-shadow: none;
	}
  
	&:not(:disabled):not(.disabled) {
	  cursor: pointer;
	}
  }
  
  .page-item {
	&:first-child .page-link {
	  margin-left: 0;
	  border-top-left-radius: 3px;
	  border-bottom-left-radius: 3px;
	}
  
	&:last-child .page-link {
	  border-top-right-radius: 3px;
	  border-bottom-right-radius: 3px;
	}
  
	&.active .page-link {
	  z-index: 1;
	  color: $white;
	}
  
	&.disabled .page-link {
	  color: #ced4da;
	  pointer-events: none;
	  cursor: auto;
	  background-color: $white;
	  border-color: rgba(215, 227, 243, 0.5);
	}
  }
  
  .pagination-lg {
	.page-link {
	  padding: 0.75rem 1.5rem;
	  font-size: 1.125rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	  }
	}
  }
  
  .pagination-sm {
	.page-link {
	  padding: 0.25rem 0.5rem;
	  font-size: 0.875rem;
	  line-height: 1.5;
	}
  
	.page-item {
	  &:first-child .page-link {
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	  }
  
	  &:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	  }
	}
  }
  
  .accordionjs .acc_section .acc_head h3 {
	&:before {
	  color: #8499c4;
	}
  
	font-weight: 400 !important;
  }
  
  /*-------Accordion-----------*/
  
  .panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: rgba(215, 227, 243, 0.5);
  }
  
  .panel-default > .panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: #f4f6fd;
	border: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .panel-title {
	font-size: 14px;
	margin-bottom: 0;
  
	> a {
	  display: block;
	  padding: 15px;
	  text-decoration: none;
	}
  }
  
  .more-less {
	float: right;
	color: #212121;
  }
  
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
	border-bottom: 1px solid rgba(215, 227, 243, 0.5);
	border-left: 1px solid rgba(215, 227, 243, 0.5);
	border-right: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  /* ----- v CAN BE DELETED v ----- */
  
  .wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
  }
  
  a {
	&:focus, &:hover, &:active {
	  outline: 0;
	  text-decoration: none;
	}
  }
  
  .panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
  
	&:last-child {
	  border-bottom: none;
	}
  }
  
  .panel-group1 {
	> .panel1:first-child .panel-heading1 {
	  border-radius: 4px 4px 0 0;
	}
  
	.panel1 {
	  border-radius: 0;
	}
  }
  
  .panel-body1 {
	padding: 10px;
  }
  
  .panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
  }
  
  .panel-group1 .panel1 + .panel1 {
	margin-top: 0;
  }
  
  .panel-heading1 {
	background-color: #5797fc;
	border-radius: 0;
	border: none;
	color: $white;
	padding: 0;
  }
  
  .panel-group1 .panel-body {
	border: 1px solid rgba(215, 227, 243, 0.5);
  }
  
  .panel-title1 a {
	display: block;
	color: $white !important;
	padding: 10px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
  }
  
  .panel-body1 {
	background: $white;
  }
  
  .panel1:last-child {
	.panel-body1 {
	  border-radius: 0 0 4px 4px;
	}
  
	.panel-heading1 {
	  border-radius: 0 0 4px 4px;
	  transition: border-radius 0.3s linear 0.2s;
  
	  &.active {
		border-radius: 0;
		transition: border-radius linear 0s;
	  }
	}
  }
  
  /* #bs-collapse icon scale option */
  
  .panel-title a.accordion-toggle {
	&:before {
	  content: "\f068";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  
	&.collapsed:before {
	  content: "\f067";
	  padding: 0 10px 0 0;
	  color: $white;
	  font-family: FontAwesome;
	  float: right;
	}
  }
  
  .panel-heading1 {
	a {
	  &.collapsed:before {
		content: "\e9af";
		position: absolute;
		font-family: 'feather' !important;
		right: 10px;
		top: 7px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
	  }
  
	  &:before {
		content: "\e994";
		position: absolute;
		font-family: 'feather' !important;
		right: 10px;
		top: 7px;
		font-size: 20px;
		transition: all 0.5s;
		transform: scale(1);
	  }
	}
  
	&.active a:before {
	  content: ' ';
	  transition: all 0.5s;
	  transform: scale(0);
	}
  }
  
  .demo-accordion p:last-child, .panel-group1 p:last-child {
	margin-bottom: 0;
  }
  
  #bs-collapse .panel-heading1 {
	a:after {
	  content: "\f01a";
	  font-size: 24px;
	  position: absolute;
	  font-family: 'FontAwesome';
	  right: 5px;
	  top: 10px;
	  transform: scale(0);
	  transition: all 0.5s;
	}
  
	&.active a:after {
	  content: "\f01a";
	  transform: scale(1);
	  transition: all 0.5s;
	}
  }
  
  /* #accordion rotate icon option */
  
  #accordion .panel-heading1 {
	a:before {
	  content: "\f01a";
	  font-size: 24px;
	  position: absolute;
	  font-family: 'FontAwesome';
	  right: 5px;
	  top: 10px;
	  transform: rotate(180deg);
	  transition: all 0.5s;
	}
  
	&.active a:before {
	  transform: rotate(0deg);
	  transition: all 0.5s;
	}
  }
  
  .accordionjs .acc_section.acc_active > .acc_head {
	color: #FFF !important;
  }
  
  .accordion-wizard .badge-pill {
	padding-right: .6em;
	padding-left: .6em;
	border-radius: 3px;
  }